<i18n>
{
  "de": {
    "nameTitle": "Name",
    "remarksTitle": "Bemerkungen",
    "heatersTitle": "Wärmeerzeugung"
  }
}
</i18n>

<template>
  <EditContainer
    :bind-form-state="formState"
    class="c-edit-heat-grid"
    :allow-edit="getPortfolioPermission('EDIT_HEAT_GRIDS')"
    @container="onFormEvent"
  >
    <FormRow v-if="model.name || isEditing" :label="$t('nameTitle')">
      <TextInput v-model="model.name" :edit="isEditing" @validation="onValidation($event, 'name')" />
    </FormRow>
    <FormRow v-if="model.remarks || isEditing" :label="$t('remarksTitle')">
      <TextInput
        v-model="model.remarks"
        :edit="isEditing"
        :allow-empty="true"
        @validation="onValidation($event, 'name')"
      />
    </FormRow>
    <EditGridHeatersTable
      v-model="model.heaters"
      :portfolio="portfolio"
      :edit="isEditing"
      @input="onHeatersChange"
      @validation="onValidation($event, 'heaters-table')"
    />
  </EditContainer>
</template>

<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'

import EditContainer from '@/components/shared/forms/EditContainer.vue'
import EditGridHeatersTable from '@/components/settings/heat_grids/EditGridHeatersTable.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    EditContainer,
    EditGridHeatersTable,
    FormRow,
    TextInput,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    heatGrid: {
      type: Object,
    },
  },

  computed: {
    totalShare() {
      return this.model.heaters.reduce((pv, cv) => pv + cv.share, 0)
    },
  },

  watch: {
    heatGrid() {
      this.resetModel()
    },
  },

  created() {
    if (!this.heatGrid) {
      // Start editing immediately when adding a new grid
      this.onFormEvent('edit')
    }
    this.resetModel()
  },

  methods: {
    ...mapActions({
      addHeatGrid: 'portfolio/addHeatGrid',
      updateHeatGrid: 'portfolio/updateHeatGrid',
    }),

    resetModel() {
      if (this.heatGrid) {
        const heaters = JSON.parse(JSON.stringify(this.heatGrid.heaters))
        this.model = {
          portfolio_id: this.heatGrid.portfolio_id,
          name: this.heatGrid.name,
          remarks: this.heatGrid.remarks,
          heaters,
          // // Convert heating type IDs to names (as expected by HeatingTypePicker)
          // heaters: heaters.map((h) => ({
          //   ...h,
          //   heating_type_id: this.portfolio.heating_types.find((ht) => ht.id === h.heating_type_id).name,
          // })),
        }
      } else {
        this.buildings = null
        this.model = {
          portfolio_id: this.portfolio.id,
          name: null,
          remarks: null,
          heaters: [
            {
              heating_type_id: 'UNKNOWN',
              share: 100,
              ghg_factor: null,
              pe_factor: null,
              energy_calibration: null,
            },
          ],
        }
      }
    },

    async saveHandler() {
      if (this.heatGrid) {
        // Update existing heat grid
        await this.updateHeatGrid({
          id: this.heatGrid.id,
          heatGrid: this.model,
          // // Convert heating type names to integer IDs
          // heatGrid: {
          //   ...this.model,
          //   heaters: this.model.heaters.map((h) => ({
          //     ...h,
          //     heating_type_id: this.portfolio.heating_types.find((ht) => ht.name === h.heating_type_id).id || 13,
          //   })),
          // },
        })
      } else {
        // Create new heat grid
        await this.addHeatGrid({
          ...this.model,
          // // Convert heating type names to integer IDs
          // heaters: this.model.heaters.map((h) => ({
          //   ...h,
          //   heating_type_id: this.portfolio.heating_types.find((ht) => ht.name === h.heating_type_id).id || 13,
          // })),
        })
      }
    },

    onHeatersChange(heaters) {
      this.model.heaters = heaters
    },
  },
}
</script>

<style lang="scss">
.c-edit-heat-grid {
  & .limit-width {
    max-width: 150px;
    display: inline-block;
  }

  & .select-width {
    min-width: 150px;
  }
}
</style>
